import Link from "next/link";

export default function Custom404() {
  return (
    <>
      <div>
        <h1>Page Not Found</h1>
        <p>
          <Link href="/">Go home</Link>
        </p>
      </div>

      <style global jsx>{`
        a {
          color: blue;
          text-decoration: underline;
        }
      `}</style>

      <style jsx>{`
        div {
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        h1 {
          font-size: 2em;
        }

        p {
          font-size: 1.5em;
          margin: 3em;
        }
      `}</style>
    </>
  );
}
